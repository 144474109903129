.parsley-mindate {
    color: #B94A48;
}

.parsley-minlength {
    color: #B94A48;
}

.parsley-custom-error-message {
    color: #B94A48;
}

.parsley-pattern {
    color: #B94A48;
}