.margin-0 {
  margin: 0px; }

.margin-5 {
  margin: 5px; }

.margin-10 {
  margin: 10px; }

.margin-15 {
  margin: 15px; }

.margin-20 {
  margin: 20px; }

.margin-25 {
  margin: 25px; }

.margin-30 {
  margin: 30px; }

.margin-40 {
  margin: 40px; }

.margin-50 {
  margin: 50px; }

.margin-60 {
  margin: 60px; }

.margin-70 {
  margin: 70px; }

.margin-80 {
  margin: 80px; }

.margin-90 {
  margin: 90px; }

.margin-100 {
  margin: 100px; }

.margin-top-0 {
  margin-top: 0px; }

.margin-top-5 {
  margin-top: 5px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-15 {
  margin-top: 15px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-25 {
  margin-top: 25px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-40 {
  margin-top: 40px; }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-60 {
  margin-top: 60px; }

.margin-top-70 {
  margin-top: 70px; }

.margin-top-80 {
  margin-top: 80px; }

.margin-top-90 {
  margin-top: 90px; }

.margin-top-100 {
  margin-top: 100px; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.margin-bottom-5 {
  margin-bottom: 5px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-25 {
  margin-bottom: 25px; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-bottom-60 {
  margin-bottom: 60px; }

.margin-bottom-70 {
  margin-bottom: 70px; }

.margin-bottom-80 {
  margin-bottom: 80px; }

.margin-bottom-90 {
  margin-bottom: 90px; }

.margin-bottom-100 {
  margin-bottom: 100px; }

.margin-left-0 {
  margin-left: 0px; }

.margin-left-5 {
  margin-left: 5px; }

.margin-left-10 {
  margin-left: 10px; }

.margin-left-15 {
  margin-left: 15px; }

.margin-left-20 {
  margin-left: 20px; }

.margin-left-25 {
  margin-left: 25px; }

.margin-left-30 {
  margin-left: 30px; }

.margin-left-40 {
  margin-left: 40px; }

.margin-left-50 {
  margin-left: 50px; }

.margin-left-60 {
  margin-left: 60px; }

.margin-left-70 {
  margin-left: 70px; }

.margin-left-80 {
  margin-left: 80px; }

.margin-left-90 {
  margin-left: 90px; }

.margin-left-100 {
  margin-left: 100px; }

.margin-right-0 {
  margin-right: 0px; }

.margin-right-5 {
  margin-right: 5px; }

.margin-right-10 {
  margin-right: 10px; }

.margin-right-15 {
  margin-right: 15px; }

.margin-right-20 {
  margin-right: 20px; }

.margin-right-25 {
  margin-right: 25px; }

.margin-right-30 {
  margin-right: 30px; }

.margin-right-40 {
  margin-right: 40px; }

.margin-right-50 {
  margin-right: 50px; }

.margin-right-60 {
  margin-right: 60px; }

.margin-right-70 {
  margin-right: 70px; }

.margin-right-80 {
  margin-right: 80px; }

.margin-right-90 {
  margin-right: 90px; }

.margin-right-100 {
  margin-right: 100px; }

.padding-0 {
  padding: 0px; }

.padding-5 {
  padding: 5px; }

.padding-10 {
  padding: 10px; }

.padding-15 {
  padding: 15px; }

.padding-20 {
  padding: 20px; }

.padding-25 {
  padding: 25px; }

.padding-30 {
  padding: 30px; }

.padding-40 {
  padding: 40px; }

.padding-50 {
  padding: 50px; }

.padding-60 {
  padding: 60px; }

.padding-70 {
  padding: 70px; }

.padding-80 {
  padding: 80px; }

.padding-top-0 {
  padding-top: 0px; }

.padding-top-5 {
  padding-top: 5px; }

.padding-top-10 {
  padding-top: 10px; }

.padding-top-15 {
  padding-top: 15px; }

.padding-top-20 {
  padding-top: 20px; }

.padding-top-25 {
  padding-top: 25px; }

.padding-top-30 {
  padding-top: 30px; }

.padding-top-40 {
  padding-top: 40px; }

.padding-top-50 {
  padding-top: 50px; }

.padding-top-60 {
  padding-top: 60px; }

.padding-top-70 {
  padding-top: 70px; }

.padding-top-80 {
  padding-top: 80px; }

.padding-bottom-0 {
  padding-bottom: 0px; }

.padding-bottom-5 {
  padding-bottom: 5px; }

.padding-bottom-10 {
  padding-bottom: 10px; }

.padding-bottom-15 {
  padding-bottom: 15px; }

.padding-bottom-20 {
  padding-bottom: 20px; }

.padding-bottom-25 {
  padding-bottom: 25px; }

.padding-bottom-30 {
  padding-bottom: 30px; }

.padding-bottom-40 {
  padding-bottom: 40px; }

.padding-bottom-50 {
  padding-bottom: 50px; }

.padding-bottom-60 {
  padding-bottom: 60px; }

.padding-bottom-70 {
  padding-bottom: 70px; }

.padding-bottom-80 {
  padding-bottom: 80px; }

.padding-left-0 {
  padding-left: 0px; }

.padding-left-5 {
  padding-left: 5px; }

.padding-left-10 {
  padding-left: 10px; }

.padding-left-15 {
  padding-left: 15px; }

.padding-left-20 {
  padding-left: 20px; }

.padding-left-25 {
  padding-left: 25px; }

.padding-left-30 {
  padding-left: 30px; }

.padding-left-40 {
  padding-left: 40px; }

.padding-left-50 {
  padding-left: 50px; }

.padding-left-60 {
  padding-left: 60px; }

.padding-left-70 {
  padding-left: 70px; }

.padding-left-80 {
  padding-left: 80px; }

.padding-right-0 {
  padding-right: 0px; }

.padding-right-5 {
  padding-right: 5px; }

.padding-right-10 {
  padding-right: 10px; }

.padding-right-15 {
  padding-right: 15px; }

.padding-right-20 {
  padding-right: 20px; }

.padding-right-25 {
  padding-right: 25px; }

.padding-right-30 {
  padding-right: 30px; }

.padding-right-40 {
  padding-right: 40px; }

.padding-right-50 {
  padding-right: 50px; }

.padding-right-60 {
  padding-right: 60px; }

.padding-right-70 {
  padding-right: 70px; }

.padding-right-80 {
  padding-right: 80px; }

@media screen and (max-width: 768px) {
  .margin-top-sm-10 {
    margin-top: 10px; }
  .margin-top-sm-20 {
    margin-top: 20px; }
  .margin-top-sm-30 {
    margin-top: 30px; }
  .margin-top-sm-40 {
    margin-top: 40px; } }

@media screen and (max-width: 768px) {
  .margin-bottom-sm-10 {
    margin-bottom: 10px; }
  .margin-bottom-sm-20 {
    margin-bottom: 20px; }
  .margin-bottom-sm-30 {
    margin-bottom: 30px; }
  .margin-bottom-sm-40 {
    margin-bottom: 40px; } }

.flash {
  background: #529fd7;
  border-radius: 4px;
  color: white;
  width: 200px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 1em;
  display: none; }

.footer-link {
  color: white; }

.footer-link:hover {
  text-decoration: underline; }

.workshop-top-spacer {
  height: 113px; }

@media screen and (min-width: 992px) {
  .workshop-top-spacer {
    height: 87px; } }

.workshop-nav {
  background: #5f7e78;
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#5f7e78, #196b73);
  /* Standard syntax (must be last) */
  border-bottom: 10px solid #529fd7;
  height: 83px; }

.workshop-nav-content {
  background: transparent;
  height: 83px;
  box-shadow: 0 -0.25em 0.9em rgba(0, 0, 0, 0.5);
  padding-top: 23px; }

.workshop-inner-container {
  border-radius: 5px;
  box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5); }

.shadow2 {
  box-shadow: 0 0.25em 0.9em rgba(0, 0, 0, 0.5); }

.workshop-nav-tabs {
  border-bottom: none; }

.workshop-nav-tab-a {
  color: #569da6;
  margin-right: 0px !important;
  border-radius: 0px !important;
  line-height: 5px !important;
  border: none !important; }

.workshop-nav-tab-a:hover {
  background: transparent !important;
  border: none !important; }

.workshop-nav-tab-a:focus {
  background: transparent !important;
  border: none !important; }

.workshop-nav-tab-active {
  background: #29625e; }

.workshop-nav-tab-text {
  display: block;
  color: white;
  margin-top: 8px;
  font-weight: bold;
  font-size: 13px; }

.workshop-container {
  background: white;
  min-height: 450px;
  padding: 30px; }

.wg-hr {
  margin-top: 0px;
  margin-bottom: 15px; }

.text-black {
  color: black; }

.style-h1 {
  color: #3e959e;
  font-size: 32px; }

.style-h2 {
  color: #3e959e;
  font-size: 24px; }

.style-h3 {
  color: #3e959e;
  font-size: 20px; }

.style-h4 {
  color: #3e959e;
  font-size: 16px; }

.wg-h1 {
  color: #529fd7;
  font-size: 32px; }

.wg-h2 {
  color: #529fd7;
  font-size: 24px; }

.wg-h3 {
  color: black;
  font-size: 18px; }

.margin-0 {
  margin: 0px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-40 {
  margin-top: 40px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-0 {
  margin: 0px; }

.padding-0 {
  padding: 0px; }

.padding-10 {
  padding: 10px; }

.padding-20 {
  padding: 20px; }

.padding-40 {
  padding: 40px; }

.border-top-lightgreen {
  border-top: 10px solid #94ba3d; }

.border-top-darkgreen {
  border-top: 10px solid #158d96; }

.border-top-blue {
  border-top: 10px solid #529fd7; }

.vertical-center {
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.static-page-header {
  background: #5f7e78;
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#5f7e78, #196b73);
  /* Standard syntax (must be last) */
  border-bottom: 10px solid #529fd7;
  height: 150px; }

.static-page-header-content {
  background: transparent;
  margin-top: -310px;
  height: 300px;
  box-shadow: 0 -0.25em 0.9em rgba(0, 0, 0, 0.5);
  padding: 50px 40px 40px 40px;
  color: white; }

.static-page-header-title {
  margin-top: 170px;
  font-size: 24px; }

.static-page-header-subtitle {
  padding: 0px;
  font-size: 16px; }

@media screen and (min-width: 768px) {
  .static-page-header-title {
    margin-top: 160px;
    font-size: 32px; } }

@media screen and (min-width: 992px) {
  .static-page-header-subtitle {
    padding: 0px 20px 0px 20px; } }

.advisors-bulletpoint {
  display: block;
  font-size: 16px;
  margin-bottom: 10px; }

.btn-custom-green {
  min-width: 100px;
  box-shadow: 0 0.2em 0.3em rgba(0, 0, 0, 0.5);
  padding: 4px 25px 4px 25px;
  background: #94ba3d;
  color: white; }

.btn-custom-green:hover {
  background-color: #66802a;
  color: white; }

.btn-custom-green:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #66802a; }

.btn-custom-green:focus {
  color: white; }

.btn-custom-orange {
  min-width: 100px;
  box-shadow: 0 0.2em 0.3em rgba(0, 0, 0, 0.5);
  padding: 4px 25px 4px 25px;
  background: #f2a134;
  color: white; }

.btn-custom-orange:hover {
  background-color: #cc7b0d;
  color: white; }

.btn-custom-orange:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #cc7b0d; }

.btn-custom-orange:focus {
  color: white; }

.btn-custom-blue {
  min-width: 100px;
  box-shadow: 0 0.2em 0.3em rgba(0, 0, 0, 0.5);
  padding: 4px 25px 4px 25px;
  background: #529fd7;
  color: white; }

.btn-custom-blue:hover {
  background-color: #2979b3;
  color: white; }

.btn-custom-blue:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #2979b3; }

.btn-custom-blue:focus {
  color: white; }

.btn-custom-grey {
  min-width: 100px;
  box-shadow: 0 0.2em 0.3em rgba(0, 0, 0, 0.5);
  padding: 4px 25px 4px 25px;
  background: #CACACA;
  color: white; }

.btn-custom-grey:hover {
  background-color: #a4a4a4;
  color: white; }

.btn-custom-grey:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #a4a4a4; }

.btn-custom-grey:focus {
  color: white; }

.btn-custom-white {
  min-width: 100px;
  box-shadow: 0 0.2em 0.3em rgba(0, 0, 0, 0.5);
  padding: 4px 25px 4px 25px;
  color: #333;
  background-color: #fff;
  border-color: #ccc; }

.btn-custom-white:hover {
  background-color: #d9d9d9;
  color: #333; }

.btn-custom-white:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #d9d9d9; }

.btn-custom-white:focus {
  color: #333; }

.table-bordered {
  border: none; }

.table-bordered > thead > tr > th {
  border-bottom: none;
  border-top: none;
  border-left: 2px solid white;
  border-right: 2px solid white;
  background: #e1e0df; }

.table.table-bordered.dataTable tbody td {
  border-bottom: 1px dotted #e1e0df;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: white; }

.table.table-bordered.dataTable tbody a {
  color: #158d96; }

.table.table-bordered.dataTable tbody a:hover {
  text-decoration: underline; }

.quiz-vert-footer {
  background: white;
  min-height: 140px;
  padding: 10px;
  margin-right: -30px;
  margin-left: -30px; }

.chart-content {
  min-height: 500px;
  background: white; }

.report-chart-title {
  line-height: 30px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  background: #158d96;
  text-align: center;
  margin-bottom: 15px; }

.eval-chart-title {
  color: black;
  font-size: 14px;
  font-weight: bold;
  background: #EAEAEA;
  margin: 15px -20px 15px -20px;
  padding: 10px; }

.chart-legend li {
  list-style-type: none;
  margin-bottom: 10px;
  cursor: pointer; }

.chart-legend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  font-size: 13px;
  margin-right: 5px; }

.pie-chart-holder {
  border: none; }

@media screen and (min-width: 992px) {
  .pie-chart-holder {
    border-right: 1px solid lightgrey; } }

.rating-stars:hover {
  text-decoration: none !important; }

.star-icon {
  color: #CACACA;
  font-size: 18px;
  position: relative; }

.star-icon.full:before {
  color: #e48e25;
  content: '\2605';
  /* Full star in UTF-8 */
  position: absolute;
  left: 0; }

.star-icon.half:before {
  color: #e48e25;
  content: '\2605';
  /* Full star in UTF-8 */
  position: absolute;
  left: 0;
  width: 50%;
  overflow: hidden; }

.style-quiz-widget {
  height: 32px;
  width: 130px;
  background: url("/img/Giving_Style_icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 4px 14px 4px 37px;
  border: 1px solid lightgrey;
  color: grey;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.1);
  display: inline-block; }

.style-quiz-widget::before {
  content: "Style Quiz"; }

.wizard-quiz-widget {
  height: 32px;
  width: 162px;
  background: url("/img/Giving_Vehicle_icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 4px 14px 4px 37px;
  border: 1px solid lightgrey;
  color: grey;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.1);
  display: inline-block; }

.wizard-quiz-widget::before {
  content: "Vehicles Wizard"; }

.eval-form-header {
  font-size: 24px;
  height: 130px;
  padding: 70px 30px 30px 30px;
  margin-right: -30px;
  margin-left: -30px; }

@media screen and (min-width: 992px) {
  .eval-form-header {
    font-size: 32px; } }

.checkbox-custom {
  width: 65px;
  padding-left: 40px; }

.help-block {
  color: #B94A48;
  font-size: 13px; }

.admin-nav-icon {
  color: #737981;
  font-size: 20px;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  width: 25px;
  transition: all 100ms ease; }

.top-menu-item {
  padding: 0px !important; }

@media screen and (min-width: 992px) {
  .top-menu-item {
    padding: 8px 7px !important; } }

.user-dropdown-menu {
  background-color: #ffffff !important;
  z-index: 100000000 !important; }

.user-dropdown-menu a {
  text-align: left !important; }

.user-dropdown-menu > li > a {
  color: #333333 !important;
  padding: 7px 20px !important; }

.user-dropdown-menu-mobile {
  background-color: #ffffff !important;
  z-index: 100000000 !important;
  min-width: 110px;
  left: 0px;
  top: 37px; }

.user-dropdown-menu-mobile a {
  text-align: left !important; }

.user-dropdown-menu-mobile > li > a {
  color: #333333 !important;
  padding: 7px 10px !important;
  width: 150px; }

.dropdown-mobile {
  display: inline-block; }

.mobile-signUp a {
  color: white; }

.user-menu-icon {
  width: 15px; }

.spinner {
  margin: 50px;
  height: 28px;
  width: 28px;
  animation: rotate 0.8s infinite linear;
  border: 8px solid white;
  border-right-color: transparent;
  border-radius: 50%;
  position: fixed;
  right: 50%;
  bottom: 50%;
  z-index: 99999999; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.spinner-modal {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: black;
  position: fixed;
  right: 0%;
  bottom: 0%;
  z-index: 9999999;
  display: none; }

@media screen and (max-width: 992px) {
  .mobile-center {
    text-align: center; } }

@media screen and (min-width: 992px) {
  .desktop-center {
    text-align: center; } }

.paypal-donate {
  float: right; }

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px; }

/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg); }

.team-flip {
  width: 165px;
  height: 165px; }

.advisors-flip {
  width: 125px;
  height: 125px; }

/* flip speed goes here */
.flipper {
  transition: 0.2s;
  transform-style: preserve-3d;
  position: relative; }

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg); }

/* back, initially hidden pane */
.back {
  overflow: hidden;
  transform: rotateY(180deg); }

.back-inner {
  background: #f6f6f6;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px; }

.home-box-section {
  padding: 0px 10px 0px; }

.home-box-section-inner {
  background-color: #edf3ff;
  padding: 0px; }

.news-box {
  min-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: 1px solid lightgrey; }

@media screen and (max-width: 992px) {
  .news-box {
    margin-top: 10px;
    min-height: 30px;
    border-right: none; } }

.news-heading {
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: grey; }

.news-title {
  font-size: 14px;
  font-weight: bold;
  color: #41929d; }

.partner-box-main {
  min-height: 110px;
  padding: 0px; }

.partner-box {
  min-height: 55px; }

.partner-img-main {
  max-width: 130px;
  max-height: 103px; }

.partner-img {
  max-width: 180px;
  max-height: 52px; }

.contributors-section {
  padding: 20px 120px 20px 120px;
  font-size: 16px; }

@media screen and (max-width: 992px) {
  .contributors-section {
    padding: 20px;
    font-size: 16px; } }

.font-size-14 {
  font-size: 14px !important; }

figure {
  display: block;
  position: relative;
  float: left;
  overflow: hidden;
  margin: 0 20px 20px 0; }

figcaption {
  position: absolute;
  background: black;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 10px 20px;
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease; }

figure:hover figcaption {
  opacity: 1; }

figure:before {
  content: "?";
  position: absolute;
  font-weight: 800;
  background: black;
  background: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 5px white;
  color: black;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  -moz-transition: all 0.6s ease;
  opacity: 0.75; }

figure:hover:before {
  opacity: 0; }

.cap-left:before {
  bottom: 10px;
  left: 10px; }

.cap-left figcaption {
  bottom: 0;
  left: -30%; }

.cap-left:hover figcaption {
  left: 0; }

.cap-right:before {
  bottom: 10px;
  right: 10px; }

.cap-right figcaption {
  bottom: 0;
  right: -30%; }

.cap-right:hover figcaption {
  right: 0; }

.cap-top:before {
  top: 10px;
  left: 10px; }

.cap-top figcaption {
  left: 0;
  top: -30%; }

.cap-top:hover figcaption {
  top: 0; }

.cap-bot:before {
  bottom: 10px;
  left: 10px; }

.cap-bot figcaption {
  left: 0;
  bottom: -30%; }

.cap-bot:hover figcaption {
  bottom: 0; }

/*# sourceMappingURL=app.css.map */
