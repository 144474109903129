.workshop-nav {
  background: #90CED0; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#90CED0, #2D8785); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#90CED0, #2D8785); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#90CED0, #2D8785); /* For Firefox 3.6 to 15 */
  background: linear-gradient(#90CED0, #2D8785); /* Standard syntax (must be last) */
  height: 170px;
}

.workshop-nav-content {
  background: transparent;
  margin-top: -93px;
  height: 83px;
  box-shadow: 0 -0.25em 0.9em rgba(0, 0, 0, 0.5);
  padding-top: 25px;
}

.workshop-bar-blue {
  background: #0091e1;
  height: 10px;
}

.shadow2 {
  box-shadow: 0 0.25em 0.9em rgba(0, 0, 0, 0.5);
}

.workshop-nav-tabs {
  border-bottom: none;
}

.workshop-nav-tab-a {
  color: white;
  margin-right: 0px !important;
  border-radius: 0px !important;
  line-height: 2.7 !important;
  border: none !important;
}

.workshop-nav-tab-a:hover {
  background: transparent !important;
  border: none !important;
}

.workshop-nav-tab-a:focus {
  background: transparent !important;
  border: none !important;
}

.workshop-nav-tab-active {
  background: #29625e;
}

.workshop-container {
  background: white;
}